import React from 'react'
import { graphql } from 'gatsby'

import GenerateurGen from '../components/GenerateurGen'
import Layout from '../components/Layout'
import SimpleContent from '../components/SimpleContent'
import GenerateurListPseudo from '../components/GenerateurListPseudo'
import GenerateurListPseudoBox from '../components/GenerateurListPseudoBox'
import GenerateurHero from '../components/GenerateurHero'
import HomeFeature from '../components/HomeFeature'

// Export Template for use in CMS preview
export const HomePageTemplate = ({ title, subtitle, featuredImage, body, pseudo }) => (
  <main>
    <GenerateurHero
      title={title}
      subtitle={subtitle}
    />
    <GenerateurGen pseudos={pseudo}/>
    <HomeFeature />
    <SimpleContent source={body} />
    <GenerateurListPseudo title={`Idées de pseudo`}>
      {pseudo.map((element) => (
        <GenerateurListPseudoBox pseudo={element.node.name} key={element.node.id}/>
      ))}
    </GenerateurListPseudo>

  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page, gen } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} pseudo={gen.edges} />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
    gen: allHomeCsv {
      edges {
        node {
          name
          id
        }
      }
    }
  }
`
